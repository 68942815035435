import React, { useState, useEffect, useCallback } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import RequestApis from 'apis/RequestApis';
import OfficeIcon from 'assets/office_icon.png';
import HomeIcon from 'assets/home_icon.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddLocationModal from 'components/LocationsPage/ManagePage/AddLocationModal/AddLocationModal';
import SuccessMessageModal from 'components/LocationsPage/ManagePage/SuccessMessageModal/SuccessMessageModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BluePin from 'assets/blue-pin.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BlackPin from 'assets/black-pin.svg';
import './MyLocationPage.css';
import { debounce, isEmpty } from 'lodash';
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
	root: {
		height: 250,
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	divider: {
		height: theme.spacing(2),
	}})
);

/**
 * Used to keep track of what box is currently displayed
 */
export const DisplayBoxState = {
	SUCCESS: 'SUCCESS', // "green box" displays success state
	CURRENT_LOCATION: 'CURRENT_LOCATION' , // "blue box" displays current location
	NOT_FOUND: 'NOT_FOUND' // default not found state
}

function DisplayBox(props) {
	const { displayBoxState } = props;

	function getLocation() {
		if (!isEmpty(displayBoxState.data) &&
			!isEmpty(displayBoxState.data.currentEmergencyResponseLocation) &&
			displayBoxState.data.currentEmergencyResponseLocation.address &&
			displayBoxState.data.currentEmergencyResponseLocation.address.normalizedAddress) {
				return <>
							Location Address: <b>{displayBoxState.data.currentEmergencyResponseLocation.address.normalizedAddress}</b>
						</>
		} else if (!isEmpty(displayBoxState.data) &&
				   !isEmpty(displayBoxState.data.currentEmergencyResponseLocation) &&
				   displayBoxState.data.currentEmergencyResponseLocation.normalizedGeoCoords &&
				   displayBoxState.data.currentEmergencyResponseLocation.normalizedGeoCoords.latitude &&
				   displayBoxState.data.currentEmergencyResponseLocation.normalizedGeoCoords.longitude) {
			return <>
						Geodetic Coordinates: <b>{displayBoxState.data.currentEmergencyResponseLocation.normalizedGeoCoords.latitude}, {displayBoxState.data.currentEmergencyResponseLocation.normalizedGeoCoords.longitude}</b>
					</>
		} else {
			return '';
		}
	}

	if (displayBoxState.status == DisplayBoxState.SUCCESS) {
		return (
			<div className="success-message">
				<CheckCircleOutlineIcon className="icon" style={{ color: '#145724' }}/>
				<div>
					<h2>Success! Your current location has been updated to:</h2>
					{ (displayBoxState.data && displayBoxState.data.currentEmergencyResponseLocation) &&
						<div>
							<div>Location Type: <b>{displayBoxState.data.currentEmergencyResponseLocation.personal ? 'Personal Location' : 'Corporate Location'}</b></div>
							<div>Location Name: <b>{displayBoxState.data.currentEmergencyResponseLocation.name}</b></div>
							<div>{getLocation()}</div>
							<div>Location Information: <b>{displayBoxState.data.currentEmergencyResponseLocation.info}</b></div>
							<div>Organization Name: <b>{displayBoxState.data.currentEmergencyResponseLocation.companyName}</b></div>
							<br/>
						</div>
					}
				</div>
			</div>
		)
	}
	else if (displayBoxState.status == DisplayBoxState.CURRENT_LOCATION) {
		return (
			<div className="success-message blue">
				<img src={BluePin} alt="Blue Pin"/>
				<div>
					<h2>We have your current location as:</h2>
					<div>Location Type: <b>{displayBoxState.data.currentEmergencyResponseLocation.personal ? 'Personal Location' : 'Corporate Location'}</b></div>
					<div>Location Name: <b>{displayBoxState.data.currentEmergencyResponseLocation.name}</b></div>
					<div>{getLocation()}</div>
					<div>Location Information: <b>{displayBoxState.data.currentEmergencyResponseLocation.info}</b></div>
					<div>Organization Name: <b>{displayBoxState.data.currentEmergencyResponseLocation.companyName}</b></div>
					<br/>
				</div>
			</div>
		);
	}
	else if (displayBoxState.status == DisplayBoxState.NOT_FOUND) {
		return (
			<div className="success-message red">
				<ErrorOutlineIcon className="icon" style={{ color: 'rgb(177 1 1)' }}/>
				<div>
					<h2>We don't know where you are!</h2>
					<div>Location Type: <b>???</b></div>
	 				<div>Location Name: <b>???</b></div>
	 				<div>Location Address: <b>???</b></div>
	 				<div>Location Information: <b>???</b></div>
					<div>Organization Name: <b>{displayBoxState.data.company && displayBoxState.data.company.name}</b></div>
	 				<br/>
				</div>
			</div>
		);
	}
}

function Loading(props) {
	return (
		props.loading &&
		<div className="circular-progress">
			<CircularProgress/>
		</div>
	);
}

const MyLocation = (props) => {
	const [errorMessage, setErrorMessage] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [displayBoxState, setDisplayBoxState] = useState({status: DisplayBoxState.NOT_FOUND, data: {} });
	const [stateSuggestions, setSuggestions] = useState([]);
	const [open, setOpen] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [noNameSuggestion, setNoNameSuggestion] = useState('');
	const [showUsersName, setShowUsersName] = useState(false);
	const [deviceDataLoaded, setDeviceDataLoaded] = useState(false);

	const classes = useStyles();
	const deviceId = props.match.params.deviceId;

	useEffect(() => {
		getCurrentLocation(false);
	}, []);

	useEffect(() => {
		const searchInputElement = document.getElementById('my-location-search-input');

		if (open) {
			searchInputElement.blur();
		}
	}, [open]);

	useEffect(() => {
        // Remove Unnecessary Aria attributes
        const domElms = document.querySelectorAll('div[role="combobox"]');

        if (domElms.length > 0) {
            domElms.forEach(elm => {
                elm.removeAttribute('aria-owns');
            });
        }
    }, []);

	function getCurrentLocation(success) {
		setLoading(true);
		setDeviceDataLoaded(false);
		RequestApis.getDevice(deviceId).then(
			response => {
				setLoading(false);
				setShowUsersName(true);
				setDeviceDataLoaded(true);
				let status;
				if(success) {
					status = DisplayBoxState.SUCCESS;
				}
				else if(response.data.currentEmergencyResponseLocation) {
					status = DisplayBoxState.CURRENT_LOCATION
				}
				else {
					status = DisplayBoxState.NOT_FOUND;
				}
				setDisplayBoxState({
						status: status,
						data: response.data
				});
				setSearchInput('');
			}
		).catch(() => {
			setDeviceDataLoaded(false);
			setLoading(false);
			setSearchInput('');
		});
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps;

		return (
		<TextField
			fullWidth
			InputProps={{
			inputRef: node => {
				ref(node);
				inputRef(node);
			},
			classes: {
				input: classes.input,
			},
			}}
			{...other}
		/>
		);
	}

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		let info;
		if (suggestion.info) {
			info = 'Info: ' + suggestion.info;
		} else {
			info = '';
		}

		function getGeoCoords(suggestion) {
			if (suggestion.normalizedGeoCoords) {
				return 'Latitude: ' + suggestion.normalizedGeoCoords.latitude + ' Longitude: ' + suggestion.normalizedGeoCoords.longitude;
			} else {
				return '';
			}
		}

		return (
		suggestion.id && suggestion.name && (suggestion.address || suggestion.normalizedGeoCoords) ?
		<MenuItem className="items-wrapper" selected={isHighlighted} component="div">
			<div className="items">
			{ suggestion.personal ?
				<img className="icon" src={HomeIcon} alt="Home Icon" />
				: <img className="icon" src={OfficeIcon} alt="Building Icon" />
			}
				<span>
					<b>{suggestion.name}</b><br/>
          {suggestion.address && <>{suggestion.address.normalizedAddress}<br/></>}
          {getGeoCoords(suggestion)}<br/>
					{suggestion.info ? 'Location Information: ' + suggestion.info : ''}
				</span>
			</div>
		</MenuItem>
		:
		<MenuItem selected={isHighlighted} component="div" style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="items">
        <img className="icon" src={BlackPin} alt="Black Pin Icon"/>
        <span>
          {suggestion.address && <>{suggestion.address.normalizedAddress}<br/></>}
          {getGeoCoords(suggestion)}<br/>
          {suggestion.info ? 'Location Information: ' + suggestion.info : ''}
				</span>
      </div>
    </MenuItem>
    );
  }

  function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

		return inputLength === 0
		? []
		: stateSuggestions && stateSuggestions.filter(suggestion => {
			const keep =
				count < 5 && suggestion.name && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue ||
				suggestion.address && suggestion.address.normalizedAddress && suggestion.address.normalizedAddress.slice(0, inputLength).toLowerCase() === inputValue
				||
				suggestion.info && suggestion.info.slice(0, inputLength).toLowerCase() === inputValue;

			if (keep) {
				count += 1;
			}

			return keep;
			});
	}

	function getSuggestionValue(suggestion) {
		return suggestion.name && suggestion.address ? suggestion.name + ' ' + suggestion.address.normalizedAddress + ' ' + suggestion.info : '';
	}

	function handleSuggestionsFetchRequested({ value }) {
		setSuggestions(getSuggestions(value));
	};

	const handleSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const handleChange = (event, { newValue }) => {
		const autosuggestElm = document.getElementById('my-location-options');
		if (autosuggestElm) {
			autosuggestElm.style.display = 'block';
		}

		searchApiCall(event.target.value)
		setSearchInput(newValue);
	};

	const searchApiCall = useCallback(
        debounce(inputValue => {
			setLoading(true);
			RequestApis.getMyLocations(inputValue, deviceId).then(
				response => {
					setLoading(false);
					setSuggestions(response.data);
				}
			).catch(() => {
				setLoading(false);
			});
        }, 500)
    , []);

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		setLoading(true);
		setSearchInput('');
		if (!suggestion.name) {
			setOpen(true);
			setNoNameSuggestion(suggestion.address.normalizedAddress);
		}
		RequestApis.setMyLocations(deviceId, suggestion.id).then(
			response => {
				setLoading(false);
				setErrorMessage(false);
				RequestApis.getDevice(deviceId).then(
					response => {
						setDisplayBoxState({
							status: DisplayBoxState.SUCCESS,
							data: response.data
						});
					}
				);
			}).catch(() => {
				setLoading(false);
				setErrorMessage(true);
				setSearchInput('');
		})
	}

	function handleClickOpen() {
		setOpen(true);
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion,
	};

  return (
    <div className="my-location-page">
			<Helmet>
				<title>{document.querySelector('title').getAttribute('data-default') + ' - My Location'}</title>
			</Helmet>
			<Loading loading={loading}/>
			<div className="logo-wrapper">
				<span id="logo"></span>
			</div>
			{showUsersName &&
				<div className="hello-message">Hello, {displayBoxState.data.firstName ? displayBoxState.data.firstName : displayBoxState.data.email}!</div>
			}
			<h1>Welcome to MyE911.</h1>
			<DisplayBox displayBoxState={displayBoxState}/>
			<div className="section">The location information above will be used by E911 Services to dispatch emergency responders to your current location should you happen to make a 911 call using this device.</div>
			<div className="section">If you'd like to update your current location, please search for a location below and select it or create a new location and set it as current.</div>
			{displayBoxState.status == DisplayBoxState.NOT_FOUND &&
				<div className="section"><b style={{ color: 'rgb(177 1 1)' }}>WARNING:</b> If you chose not to update you location there may be a delay in emergency responders being dispatched as you location will need to be validated by a 911 Call Center.</div>
			}
			<div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginTop: '30px', width: '100%', maxWidth: 770 }}>
				<div style={{ fontWeight: 'bold', fontSize: '1.17em', margin: '1em 0'}} htmlFor="my-location-search-input">Let's find you...</div>
				<Button className="primary"  onClick={handleClickOpen}>Add Location and Set as Current</Button>
			</div>
			<Autosuggest
				{...autosuggestProps}
				inputProps={{
					classes,
					id: 'my-location-search-input',
					placeholder: 'Start entering in an address or name of location here',
					value: searchInput,
					onChange: handleChange,
					'aria-controls': null,
				}}
				onSuggestionSelected={onSuggestionSelected}
				theme={{
					container: classes.container,
					suggestionsContainerOpen: classes.suggestionsContainerOpen,
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion,
				}}
				renderSuggestionsContainer={options => {
					if (options.children) {
						document.body.classList.add('search-options');
					} else {
						document.body.classList.remove('search-options');
					}
					window.scrollTo(0,document.body.scrollHeight);
					return (
						<div>
							<Paper
								{...options.containerProps}
								square
								id="my-location-options"
								>
								{options.children}
							</Paper>
						</div>
					)}
				}
			/>
			{ errorMessage && !noNameSuggestion ?
				<div className="form-error-message">Sorry we could not set your location, please try again.</div>
			: ''
			}
			{ open &&
				<AddLocationModal
					open={open}
					setOpen={setOpen}
					addressValue={noNameSuggestion ? noNameSuggestion : searchInput}
					deviceId={deviceId}
					deviceInfo={displayBoxState.data}
					setOpenSuccessModal={setOpenSuccessModal}
					getCurrentLocation={getCurrentLocation}
					setSearchInput={setSearchInput}/>
			}
			{ openSuccessModal && deviceDataLoaded &&
				<SuccessMessageModal
					deviceInfo={displayBoxState.data}
					setOpenSuccessModal={setOpenSuccessModal}
					openSuccessModal={openSuccessModal} />
			}
    </div>
  );
}

export default MyLocation;
