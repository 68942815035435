import React, { useEffect, useState } from 'react';
import RequestApis from 'apis/RequestApis';
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const EX_TOKEN_PARAM = 'exToken';
const VERSION_UPDATE = 'versionUpdate';

const HandleAuthorizationRedirect = props => {
    const { success, failed, deviceId, versionUpdate } = props; 
    if (success)
        return <Redirect to={{pathname: "/locations/" + deviceId + "/my-location", state: { authorized: true,  versionUpdate }}}/>
    else if (failed)
        return <Redirect to={{pathname: "/unauthorized"}} />
    else
        return <div>...Loading</div>
}

const Login = props => {
    const [exchangeTokenRequestSuccess, setExchangeTokenRequestSuccess] = useState(false);
    const [exchangeTokenRequestFailed, setExchangeTokenRequestFailed] = useState(false);
    const deviceId = props.match.params.deviceId;
    const urlParams = new URLSearchParams(props.location.search);
    const exchangeToken = urlParams.get(EX_TOKEN_PARAM);
    const versionUpdate = (urlParams.get(VERSION_UPDATE) === 'true');
 
    useEffect(() => {
        RequestApis.tokenExchange(exchangeToken).then(response => {
            localStorage.setItem('mye911_access_token', response.data.accessToken);
            localStorage.setItem('mye911_refresh_token_id', response.data.refreshTokenInfo.id);
            localStorage.setItem('mye911_refresh_token_exp', response.data.refreshTokenInfo.exp);
            setExchangeTokenRequestSuccess(true);
        }).catch(() => {
            setExchangeTokenRequestFailed(true);
        });
    }, []);

    return (
        <div>
            <Helmet>
                <title>{document.querySelector('title').getAttribute('data-default') + ' - Log In'}</title>
            </Helmet>
            <HandleAuthorizationRedirect 
                success={exchangeTokenRequestSuccess} 
                failed={exchangeTokenRequestFailed} 
                deviceId={deviceId}
                versionUpdate={versionUpdate}
            />
        </div>
    );
}

export default Login;