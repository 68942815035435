// polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import './five9.css';
import App from 'components/App/App';
import Startup from 'utils/Startup/Startup';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from 'utils/ErrorBoundary/ErrorBoundary';
import history from 'utils/History/History';
import Theme from './components/Theme/Theme';
import ConditionalStyling from './components/ConditionalStyling/ConditionalStyling';


// polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

if (window.location.href.includes('five9')) {
    localStorage.setItem('skin', 'five9');
} else if (window.location.href.includes('default')) {
    localStorage.setItem('skin', 'default');
}

if (localStorage.getItem('skin') === 'five9') {
    document.body.classList.add('five9');
}

ReactDOM.render(
    <Startup>
        <BrowserRouter basename={process.env.PUBLIC_URL} history={history}>
            <ErrorBoundary>
                <Theme>
                    <ConditionalStyling>
                        <App />
                    </ConditionalStyling>
                </Theme>
            </ErrorBoundary>
        </BrowserRouter>
    </Startup>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
