import React from 'react';
import { Switch, Route } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import MyLocationPage from 'components/LocationsPage/MyLocationPage/MyLocationPage';
import ManagePage from 'components/LocationsPage/ManagePage/ManagePage';
import UserDetailsPage from 'components/LocationsPage/UserDetailsPage/UserDetailsPage';

import SideNav from 'utils/SideNav/SideNav';
import VersionUpdateBanner from 'utils/VersionUpdateBanner/VersionUpdateBanner';

const useStyles = makeStyles(theme => ({
	locationPage: {
		flexGrow: 1,
		maxWidth: '1190px',
		paddingRight: '60px'
	}})
);

const LocationsPage = (props) => {
	const classes = useStyles();
	return (
		<div className="main-content">
			<SideNav {...props} />
			<div className={classes.locationPage}>
				{ props.location.state && props.location.state.versionUpdate ? <VersionUpdateBanner/> : null }
				<Switch>
					<Route path="/locations/:deviceId/my-location" component={MyLocationPage} {...props}/>
					<Route path="/locations/:deviceId/manage" component={ManagePage} {...props}/>
					<Route path='/locations/:deviceId/userDetails' component={UserDetailsPage} {...props}/>
				</Switch>
			</div>
		</div>
	);
}

export default LocationsPage;