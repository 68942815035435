import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import RequestApis from 'apis/RequestApis';
import ReactTableLoadingComponent from '../../../utils/ReactTableLoadingComponent/ReactTableLoadingComponent';

import { Tooltip } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import DomainIcon from '@material-ui/icons/Domain';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import SuccessMessageModal from 'components/LocationsPage/ManagePage/SuccessMessageModal/SuccessMessageModal';
import EditMenu from 'components/LocationsPage/ManagePage/EditMenu/EditMenu';
import './ManagePage.css';
import { debounce } from 'lodash';
import { Helmet } from "react-helmet";

const initialSortColumns = [
    {
        id: 'name',
        desc: false
    }
];

const Manage = props => {
    const [searchValue, setSearchValue] = useState('');
    const [refreshList, setRefreshList] = useState(false);
    const [getDeviceInfo, setGetDeviceInfo] = useState(null);
    const [deviceDataLoaded, setDeviceDataLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [setMyLocationsLoading, setSetMyLocationLoading] = useState(false);
    const [locationFilter, setLocationFilter] = useState('personal-locations');

    const [locationsType, setLocationsType] = useState('PERSONAL');
    const [locationsData, setLocationsData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(1);
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);

    const deviceId = props.match.params.deviceId;

    function handleRadioChange(event, row) {
        if (!openSuccessModal) {
            setSetMyLocationLoading(true);
            RequestApis.setMyLocations(deviceId, row.original.id).then(response => {
                setSetMyLocationLoading(false);
                loadTableData(searchValue);
                getDeviceData();
                setOpenSuccessModal(true);
            }).catch(error => {
                setSetMyLocationLoading(false);
                console.log(error);
            });
        }
    }

    function handleInputSearchChange(e) {
        setSearchValue(e.target.value);
    }

    function handleChange(event) {
        if (event.target.value === 'personal-locations') {
            setLocationsType('PERSONAL');
        } else if (event.target.value === 'corporate-locations') {
            setLocationsType('CORPORATE');
        } else {
            setLocationsType('ANY');
        }
        setLocationFilter(event.target.value);
    }

    function loadTableData(searchValue) {
        setLoading(true);
        RequestApis.getMyE911Locations(deviceId, locationsType, searchValue, pageNumber > 0 ? pageNumber + 1 : 0, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC').then(
            response => {
                setLoading(false);
                setRefreshList(false);
                setLocationsData(response.data);
                setPageCount(response.headers['x-pagination-count']);
            }
        ).catch(() => {
            setLoading(false);
        });
    }

    function getDeviceData() {
        setDeviceDataLoaded(false);
        RequestApis.getDevice(deviceId).then(
            response => {
                setGetDeviceInfo(response.data);
                setDeviceDataLoaded(true);
            }
        ).catch((error) => {
            console.log(error);
        });
    }

    const debouncedLocationDataLoad =  useCallback(
        debounce((searchValue) => {
            loadTableData(searchValue);
    }, 500), [deviceId, locationsType, pageNumber, pageSize, currentSortColumn]);

    useEffect(() => {
        loadTableData(searchValue);
    }, [locationsType, pageNumber, pageSize, currentSortColumn]);

    useEffect(() => {
        if (refreshList) {
            loadTableData(searchValue);
        }
    }, [refreshList]);

    useEffect(() => {
        debouncedLocationDataLoad(searchValue);
    }, [searchValue]);

    useEffect(() => {
        getDeviceData();
    }, []);

    useEffect(() => {
        setPageNumber(0);
    }, [pageCount]);

    function getAddressOrCoordinates(row) {
        if (row.original.address && row.original.address.normalizedAddress) {
            return (
                <div title={`${row.original.address.normalizedAddress}`}>{`${row.original.address.normalizedAddress}`}</div>
            );
        } else if (row.original.normalizedGeoCoords && row.original.normalizedGeoCoords.latitude && row.original.normalizedGeoCoords.longitude) {
            return (
                <div title={`${row.original.normalizedGeoCoords.latitude} ${row.original.normalizedGeoCoords.longitude}`}>
                    {`${row.original.normalizedGeoCoords.latitude}, ${row.original.normalizedGeoCoords.longitude}`}
                </div>
            );
        } else {
            return '';
        }
    }

    const columns = [
        {
            Header: '',
            maxWidth: 65,
            Cell: row => {
                return (
                    <Tooltip 
                        title={getDeviceInfo && getDeviceInfo.currentEmergencyResponseLocation && getDeviceInfo.currentEmergencyResponseLocation.id === row.original.id ? 'Your current location.' : 'Set this as your current location.'} 
                        >
                        <Radio
                            checked={getDeviceInfo && getDeviceInfo.currentEmergencyResponseLocation && getDeviceInfo.currentEmergencyResponseLocation.id === row.original.id}
                            onChange={(event) => handleRadioChange(event, row)}
                            style={{ color: '#1d5e75' }}
                            value={(row.index+1).toString()}
                            color="primary"
                            disabled={setMyLocationsLoading}
                            inputProps={{
                                'aria-label': 'Location Selection Button',
                                tabIndex: -1
                            }}
                        />
                    </Tooltip>
                )
            }
        }, 
        {
            Header: '',
            maxWidth: 65,
            Cell: row => (
                row.original.personal ? <HomeIcon className="icons"/> : <DomainIcon className="icons"/>
            )
        }, 
        {
            Header: 'Name',
            accessor: 'name',
            id: 'name',
            sortable: true
        }, 
        {
            Header: 'Address / Geodetic Coordinates',
            Cell: row => getAddressOrCoordinates(row),
            id: 'address',
        }, 
        {
            Header: 'Info',
            accessor: 'info',
            id: 'info',
        }, {
            Header: '',
            Cell: row => {
                return (
                    row.original.personal ? 
                    <EditMenu row={row} setRefreshList={setRefreshList} getDeviceInfo={getDeviceInfo} deviceId={deviceId} /> : ''
                );
            }
        }
    ];

    return (
        <div className="manage-page">
            <Helmet>
                <title>{document.querySelector('title').getAttribute('data-default') + ' - Manage Locations'}</title>
            </Helmet>
            <div className="logo-wrapper">
                <span id="logo"></span>
			</div>
            <h1>Manage Locations</h1>
            <input
                aria-label="Manage Location Search"
                placeholder="Filter by address or name"
                className="search-input"
                value={searchValue}
                onChange={e => {
                    handleInputSearchChange(e);
                }}
            />
            <div className="location-filter-wrapper" style={{ float: 'right'}}>
                <InputLabel htmlFor="location-filter">Location Type</InputLabel>
                <Select
                    className="location-filter"
                    value={locationFilter}
                    onChange={handleChange}
                    inputProps={{
                        id: 'location-filter',
                    }}
                    >
                    <MenuItem value="personal-locations">Personal Locations</MenuItem>
                    <MenuItem value="corporate-locations">Corporate Locations</MenuItem>
                    <MenuItem value="all-locations">All Locations</MenuItem>
                </Select>
            </div>

            <ReactTable
                sortable={false}
                resizable={false}
                className="ReactTable"
                data={locationsData}
                columns={columns}
                minRows={locationsData && locationsData.length > 0 ? 0 : 5}
                showPaginationBottom={true}
                page={pageNumber}
                pages={pageCount <= 0 ? 1 : pageCount}
                defaultPageSize = {25}
                manual
                sorted={sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = [...sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = true;
                            sortedArray[index] = resetColumn;
                        }
                        setCurrentSortColumn(currentSortColumn);
                        setSortColumns(sortedArray);
                    })
                }}
                onPageChange={(pageIndex) => {
                    setPageNumber(pageIndex);
                }}
                onPageSizeChange={pageSize => {
                    setPageSize(pageSize);
                }}
                loading={loading}
                LoadingComponent={ReactTableLoadingComponent}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                                return {
                                    tabIndex: 0,
                                    onKeyDown: e => {
                                        if ((e.keyCode === 13) && document.activeElement.tagName !== 'BUTTON') {
                                            e.preventDefault();
                                            const radioButton = document.querySelectorAll('input[type="radio"]')[rowInfo.index];
                                            if (radioButton) {
                                                radioButton.click();
                                            }
                                        }
                                    }
                                };
                        } else {
                            return {};
                        }
                    }
                }
            />
            { deviceDataLoaded && openSuccessModal ?
                <SuccessMessageModal 
                    deviceInfo={getDeviceInfo}
					alternateMessage={true}
					setOpenSuccessModal={setOpenSuccessModal}
					openSuccessModal={openSuccessModal} />
                : ''
			}
        </div>
    );
}

export default Manage;