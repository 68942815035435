import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function ReactTableLoadingComponent(props) {
    return (
        props.loading ? 
            <div className='-loading -active'>
                <div className='-loading-inner'>
                    <CircularProgress style={{ color: '#1b536a' }}/>
                </div>
            </div>
        : null
    )
}

export default ReactTableLoadingComponent;