import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RequestApis from 'apis/RequestApis';
import CircularProgress from '@material-ui/core/CircularProgress';
import './DeleteLocationModal.css';

export default function DeleteLocationModal(props) {
  const deviceId = props.deviceId;
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  function handleClose() {
    props.setOpen(false);
  }

  function handleDelete() {
      setLoading(true);
      RequestApis.deleteLocation(props.row.original.id, deviceId).then(
        () => {
            setLoading(false);
            handleClose();
            props.setRefreshList(true);
        }
      ).catch(error => {
        setLoading(false);
        if (error.response && error.response.status >= 500) {
          setErrorMessage('The server is having issues. Please try again later.');
        } else if (!error.response) {
          setErrorMessage('The server is having issues. Please try again later.');
        } else {
          setErrorMessage(error.response.data.title);
        }
      })
  }

  return (
    <Dialog
      className="delete-location-modal"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      { loading &&
          <div className="circular-progress">
            <CircularProgress/>
          </div>
      }
      { errorMessage &&
        <div className="error-message-text">{errorMessage}</div>
      }
      <DialogTitle id="alert-dialog-title">Delete Location</DialogTitle>
      <DialogContent>
          <div>Please confirm that you would like to delete the following location:</div>
          <div
            style={{
				margin: '15px 0 10px',
				textAlign: 'center'
            }}
          ><b>{props.row.original.name}</b></div>
          <Button className="primary" onClick={handleClose}>Cancel</Button>
          <Button className="warning" onClick={handleDelete}>Delete</Button>
      </DialogContent>
    </Dialog>
  );
}