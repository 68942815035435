import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/RedSky_Everbridge.svg';
import OsDetection from 'utils/OsDetection/OsDetection';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from '@material-ui/core';
import './SideNav.css';

const SideNav = props => {
    const helpUrlOSX = 'https://resources.e911cloud.com/manuals/MyE911%204%20for%20OSX%20User%20Guide.pdf';
    const helpUrlWindows = 'https://resources.e911cloud.com/manuals/MyE911%204%20for%20Windows%20User%20Guide.pdf';

    const [removeMobileItems, setRemoveMobileItems] = useState(true);

    useEffect(() => {
        const bodyEl = document.querySelector('body');

        function windowWidthCheck() {
            if (window.innerWidth <= 1240) {
                setRemoveMobileItems(false);
                bodyEl.classList.add('collapse-nav');
            } else {
                setRemoveMobileItems(true);
                bodyEl.classList.remove('collapse-nav');
            }
        }
        
        window.addEventListener('resize', () => {
            windowWidthCheck();
        });
        windowWidthCheck();


        const specifiedElement = document.querySelector('.sidenav');

        document.addEventListener('click', function(event) {
            const isClickInside = specifiedElement.contains(event.target);

            if (!isClickInside) {
                bodyEl.classList.remove('overlay-nav');   
            }
        });
    }, []);

    useEffect(() => {
        const bodyEl = document.querySelector('body');

        bodyEl.classList.remove('overlay-nav');
    }, [window.location.pathname]);

    function handleNavExpand() {
        const bodyEl = document.querySelector('body');

        bodyEl.classList.toggle('overlay-nav');
    }

    return (
        <div className="sidenav">
            {removeMobileItems &&
                <span id="sidenav-logo"></span>
            }
            { !props.hideSideLinks &&
                <div>
                    {!removeMobileItems &&
                        <Button className="menu-icon" onClick={handleNavExpand}>
                            <MenuIcon style={{ color: '#155F74' }}/>
                        </Button>
                    }
                    <ul>
                        <li><Link to={ {pathname:`/locations/${props.match.params.deviceId}/my-location`, state: {authorized : true, versionUpdate: props.location.state && props.location.state.versionUpdate}} }>My Location</Link> </li>
                        <li><Link to={ {pathname:`/locations/${props.match.params.deviceId}/manage`, state: {authorized : true, versionUpdate: props.location.state && props.location.state.versionUpdate}} }>Manage Locations</Link></li>
                        <li><Link to={ {pathname:`/locations/${props.match.params.deviceId}/userDetails` , state: {authorized : true, versionUpdate: props.location.state && props.location.state.versionUpdate}}}>User Details</Link></li>
                        <li><a href={OsDetection() === 'Windows' ? helpUrlWindows : OsDetection() === 'Mac OS' ? helpUrlOSX : ''} target="_blank">Help</a></li>
                    </ul>
                </div>
            }
            {localStorage.getItem('skin') === 'five9' && 
                <div className="bottom-wrapper">
                    <img src={Logo} alt="Redsky Technologies " />
                </div>
            }
        </div>
    );
}

export default SideNav;