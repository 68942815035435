import React, { useState } from 'react';
import EditLocationModal from 'components/LocationsPage/ManagePage/EditLocationModal/EditLocationModal';
import DeleteLocationModal from 'components/LocationsPage/ManagePage/DeleteLocationModal/DeleteLocationModal';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';

const EditMenu = props => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
            <div className="edit-menu">
                <Tooltip 
                    title="Edit this location." >
                    <Button
                     onClick={event => {
                    setOpenEditModal(true);
                    }}>
                        <EditLocationIcon className="icons"/>
                    </Button>
                </Tooltip>
                { props.getDeviceInfo && props.getDeviceInfo.currentEmergencyResponseLocation && props.getDeviceInfo.currentEmergencyResponseLocation.id === props.row.original.id ?
                    <Tooltip 
                        title="You cannot delete a location set as your current location." >
                        <Button 
                            style={{ opacity: '0.5' }}
                            >
                                <DeleteIcon className="icons"/>
                        </Button>
                    </Tooltip>
                    :
                    <Tooltip 
                        title="Delete this location." >
                        <Button 
                            onClick={event => {
                            setOpenDeleteModal(true);
                        }}>
                            <DeleteIcon className="icons"/>
                        </Button>
                    </Tooltip>
                }
                { openEditModal &&
                    <EditLocationModal getDeviceInfo={props.getDeviceInfo} deviceId={props.deviceId} row={props.row} open={openEditModal} setOpen={setOpenEditModal} setRefreshList={props.setRefreshList} />
                }
                { openDeleteModal &&
                    <DeleteLocationModal deviceId={props.deviceId} row={props.row} open={openDeleteModal} setOpen={setOpenDeleteModal} setRefreshList={props.setRefreshList} />
                }
            </div>
    );
}

export default EditMenu;