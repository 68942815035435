import { createMuiTheme } from '@material-ui/core/styles';

/* 
* This code controls the default styles for the 
* MaterialUI elements. Please try to use the below naming convention 
* to target the specified elements and classes
*/
export const DefaultTheme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiButton: {
            root: {
                '&$containedPrimary': {
                    background: '#1b536a'
                },
                '&$containedPrimary:hover': {
                    background: '#21617d'
                },
                '&$containedSecondary': {
                    background: 'rgb(230, 0, 0)'
                },
                '&$containedSecondary:hover': {
                    background: 'rgb(191 3 3)'
                },
                '&$contained$disabled': {
                    color: 'rgb(97, 95, 87)',
                    background: 'rgb(228, 227, 227)',
                }
            }
        },
        MuiButtonBase: {
            root: {
                '&$focusVisible': {
                    outline: '3px solid orangered'
                },
            }
        },
        MuiInput: {
            root: {
                '&$disabled': {
                    color: 'rgb(97, 95, 87)'
                },
                '&$focused:after': {
                    borderBottomColor: '#1b536a'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: '#1b536a',
                  }
            }
        },
        MuiMenuItem: {
            root: {
                '&:focus': {
                    background: 'rgba(0, 0, 0, 0.05)'
                },
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: '#000',
                '&$disabled': {
                    color: 'rgb(100, 100, 100)',
                },
                '&$error': {
                    color: 'rgb(230, 0, 0)',
                },
                '&$focused': {
                    color: '#1b536a',
                } 
            }
        },
        MuiRadio: {
            root: {
                '&$colorPrimary$checked': {
                    color: '#1b536a'
                }
            }
        },
        MuiCheckbox: {
            root: {
                '&$colorPrimary$checked': {
                    color: '#1b536a'
                }
            }
        },
        MuiSwitch: {
            bar: {
                background: '#1b536a !important'
            }
        },
        MuiPrivateSwitchBase: {
            root: {
                '&$checked': {
                    color: '#1b536a !important',
                },
                '&$checked$disabled': {
                    color: '#1b536a78 !important',
                }
            }
        },
        MuiPrivateTabIndicator: {
            colorPrimary: {
                background: '#1b536a !important'
            }
        },
        MuiTab: {
            root: {
                '&$selected': {
                    color: '#1b536a !important'
                }
            }
        },
    }
});