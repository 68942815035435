import React, { useState, useEffect, useCallback } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import RequestApis from 'apis/RequestApis';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		height: 250,
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	divider: {
		height: theme.spacing(2),
	}})
);

const AddressAutoSuggest = (props) => {
	const [myLocations, setMyLocations] = useState([]);
	const [state, setState] = useState({single: ''});
	const [stateSuggestions, setSuggestions] = useState([]);

	const classes = useStyles();
	const deviceId = props.deviceId;

	useEffect(() => {
		props.setAddressFieldValue(props.address);

		setState({
			single: props.address
		});
	}, []);

	useEffect(() => {
		props.values.address = state.single;
	}, [state.single])

	useEffect(() => {
        // Remove Unnecessary Aria attributes
        const domElms = document.querySelectorAll('div[role="combobox"]');

        if (domElms.length > 0) {
            domElms.forEach(elm => {
                elm.removeAttribute('aria-owns');
            });
        }
    }, []);

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps;

		return (
		<div>
			<TextField
				{...other}
				id="address"
				label="*Address"
				margin="normal"
				variant="outlined"
				name="address"
				fullWidth
				InputProps={{
				inputRef: node => {
					ref(node);
					inputRef(node);
				},
				classes: {
					input: classes.input,
				},
				}}
				onKeyDown={e => {
					if (e.keyCode === 38 || e.keyCode === 40) {
						e.preventDefault();
					}
				}}
				error={props.error}
				onBlur={props.handleBlur}
			/>
		</div>
		);
	}

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		const matches = suggestion.address ? match(suggestion.address.normalizedAddress, query) : '';

		const partsWithNoName = suggestion.address ? parse(suggestion.address.normalizedAddress, matches) : '';

		return (
		!suggestion.name &&
		<MenuItem selected={isHighlighted} component="div">
			<div className="items">
			{partsWithNoName.map((part, index) => {
				return <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
				{part.text}
				</span>
			})
			}
			</div>
		</MenuItem>
		);
	}

	function getSuggestions(value) {
		const inputValue = deburr(value.trim()).toLowerCase();
		const inputLength = inputValue.length;
		let count = 0;

		return inputLength === 0
		? []
		: myLocations && myLocations.filter(suggestion => {
			const keep =
				count < 5 && suggestion.name && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue ||
				suggestion.address && suggestion.address.normalizedAddress && suggestion.address.normalizedAddress.slice(0, inputLength).toLowerCase() === inputValue
				||
				suggestion.info && suggestion.info.slice(0, inputLength).toLowerCase() === inputValue;

			if (keep) {
				count += 1;
			}

			return keep;
			});
	}

	function getSuggestionValue(suggestion) {
		return suggestion.name && suggestion.address ? suggestion.name + ' ' + suggestion.address.normalizedAddress + ' ' + suggestion.info : '';
	}

	function handleSuggestionsFetchRequested({ value }) {
		setSuggestions(getSuggestions(value));
	};

	const handleSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const handleChange = name => (event, { newValue }) => {
		props.setAddressFieldValue(event.target.value);
		setState({
			single: event.target.value
		});

		searchApiCall(event.target.value, newValue);

		setState({
			...state,
			[name]: newValue,
		});
	};

    const searchApiCall = useCallback(
        debounce(inputValue => {
			RequestApis.getMyLocations(inputValue, deviceId).then(
				response => {
					setMyLocations(response.data);
				}
			);
        }, 500)
    , []);

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (!suggestion.name) {
			props.setAddressFieldValue(suggestion.address.normalizedAddress);

      searchApiCall(suggestion.address.normalizedAddress, deviceId);

			setState({
				single: suggestion.address.normalizedAddress
			});
		}
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion,
	};

  return (
          <Autosuggest
              {...autosuggestProps}
              inputProps={{
                  classes,
                  id: 'add-location-search-input',
                  value: state.single,
                  onChange: handleChange('single'),
				  'aria-controls': null,
                }}
              onSuggestionSelected={onSuggestionSelected}
              theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={options => {
				  return (
                  <div>
                      <Paper
						{...options.containerProps}
					  	style={{ zIndex: '99999' }}
						id="add-location-options"
					 	square >
                      {options.children}
                      </Paper>
                  </div>
				  )}}
          />
  );
}

export default AddressAutoSuggest;
