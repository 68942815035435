import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Helmet } from "react-helmet";
import './UnauthorizedPage.css';

const Unauthorized = () => {
    useEffect(() => {
        document.body.classList.add('hide-footer');

        return () => {
            document.body.classList.remove('hide-footer');
        }
    }, []);

    return (
        <div className="unauthorized-page">
            <Helmet>
                <title>{document.querySelector('title').getAttribute('data-default') + ' - Unauthorized'}</title>
            </Helmet>
            <h1>Unauthorized</h1>
            <div className="section">
                <ErrorOutlineIcon className="icon" style={{ color: '#fb4d4c', fontSize: 55 }}/>
                <h2>Your session has expired!</h2>
            </div>
            <p>You will need to open a new browser page via your MyE911 client.</p>
        </div>
    );
}

export default Unauthorized;