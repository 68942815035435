import React from 'react';
import { Route, Redirect } from 'react-router';

const AuthorizedRoute = ({ component: Component, ...props}) => {
    const urlParams = new URLSearchParams(props.location.search);
    const isMyE911Lite = sessionStorage.getItem('mye911_lite');

    return (
        <Route {...props} render={
                renderProps => {
                    return (
                        ((props.location.state && 
                        props.location.state.authorized) || urlParams.get('mye911Lite')) &&
                        Date.now() < new Date(Number.parseInt(localStorage.getItem('mye911_refresh_token_exp')))
                    ) ? 
                    <Component {...renderProps} /> : 
                    isMyE911Lite || urlParams.get('mye911Lite') || props.location.state.mye911Lite ?
                    <Redirect to="/" />
                    :
                    <Redirect to="/unauthorized" />
                }
        }/>
    );
}

export default AuthorizedRoute;