import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Formik } from "formik";
import * as Yup from "yup";
import RequestApis from "../../../apis/RequestApis";

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {  green } from '@material-ui/core/colors';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import NumberFormat from 'react-number-format';
import Error from '@material-ui/icons/Error'; 

import DeviceUsersListTable from "../../../containers/DeviceUsersListTable/DeviceUsersListTable";
import { Helmet } from "react-helmet";
import './UserDetailsPage.css';


const useStyles = makeStyles(theme => ({
	content: {
		paddingLeft: 100,
		paddingRight: 100
	},
	btn: {
		marginTop: 35
	},
	buttonProgress: {
		color: green[600],
		position: 'absolute',
	},
	errMsg: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '3px',
        color: '#D8000C',
        backgroundColor: '#FFBABA'
    }
}));

const useStylesSnackBar = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 5,
		fontSize: 18,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
		fontSize: 16,
    },
}));

function ErrorMessage(props) {
	return (
		<div className={props.classes.errMsg}>
			<Error/>
			<span>{props.message}</span>
		</div>
 	);
}

const UserDetailForm = props => {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		userSubmitLoading,
		classes,
		setFieldValue,
		setFieldTouched,
		user,
		errorMessage
	} = props;

	const phoneNumberhandleOnChange = (values) => {
        if (values.value) {
            setFieldValue('phoneNumber', values.value);
        } else {
            setFieldValue('phoneNumber', '');
        }
        setFieldTouched('phoneNumber', true);
    }

	const callbackNumberhandleOnChange = (values) => {
        if (values.value) {
            setFieldValue('callbackNumber', values.value);
        } else {
            setFieldValue('callbackNumber', '');
        }
        setFieldTouched('callbackNumber', true);
    }

	return (
		<div>
			<form className="user-details-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
				{errorMessage && !userSubmitLoading && <ErrorMessage message={errorMessage} classes={classes}/>}
				<br/>
				<TextField
					id="firstName"
					label="First Name"
					margin="normal"
					name="firstName"
					type="text"
					onChange={handleChange}
					value={values.firstName}
					onBlur={handleBlur}
					fullWidth={true}
					error={touched.firstName && Boolean(errors.firstName)}
					disabled={userSubmitLoading}/>
					<span className="error-prompt">{touched.firstName ? errors.firstName : ""}</span>
				<br/>
				<TextField
					id="lastName"
					label="Last Name"
					margin="normal"
					name="lastName"
					type="text"
					onChange={handleChange}
					value={values.lastName}
					onBlur={handleBlur}
					fullWidth={true}
					error={touched.lastName && Boolean(errors.lastName)}
					disabled={userSubmitLoading}/>
					<span className="error-prompt">{touched.lastName ? errors.lastName : ""}</span>
                <TextField
					id="email"
					label="Email"
					margin="normal"
					name="email"
					type="text"
					onChange={handleChange}
					value={values.email}
					onBlur={handleBlur}
					fullWidth={true}
					disabled={true}/>
				{!(user.alternateIdList && user.alternateIdList.length > 0) &&
					 <div>
						<NumberFormat 
							autoComplete="off"
							id="phoneNumber"
							label="*Phone Number"
							margin="normal"
							name="phoneNumber"
							type="tel"
							onValueChange={phoneNumberhandleOnChange}
							value={values.phoneNumber}
							onBlur={handleBlur}
							disabled={true}
							fullWidth={true}
							error={touched.phoneNumber && Boolean(errors.phoneNumber)}
							format="(###) ###-####" 
							customInput={TextField}
							mask="_"/>
							<span className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
						</div>
				}
				{(user.alternateIdList && user.alternateIdList.length > 0) &&
				<div>
					<NumberFormat 
						autoComplete="off"
						id="callbackNumber"
						label="*Callback Number"
						margin="normal"
						name="callbackNumber"
						type="tel"
						onValueChange={callbackNumberhandleOnChange}
						value={values.callbackNumber}
						onBlur={handleBlur}
						disabled={userSubmitLoading}
						fullWidth={true}
						error={touched.callbackNumber && Boolean(errors.callbackNumber)}
						format="(###) ###-####" 
						customInput={TextField}
						mask="_"/>
						<span className="error-prompt">{touched.callbackNumber ? errors.callbackNumber : ""}</span>
					</div>
				}
				{(user.alternateIdList && user.alternateIdList.length > 0) &&
					<DeviceUsersListTable
						id="deviceUserIds"
						margin="normal"
						value={user.alternateIdList}
						disabled={true}
					/>
				}
				<Grid container direction="column" justify="center" alignItems="center">
					<Button id="my-account-submit-button"
						type="submit"
						className="primary"
						variant="contained"
						disabled={userSubmitLoading}>	
						Save
					</Button>
					{userSubmitLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</Grid>
			</form>
		</div>
	);
};


export default function UserDetailsPage(props) {
	
    const [loadingUserProfile, setLoadingUserProfile] = useState(false);
    const [user, setUser] = useState({});
	const classes = useStyles();
    const classesSnackbar = useStylesSnackBar();
    const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [userSubmitLoading, setUserSubmitLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

    
    const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackBarOpen(false);
    };


    useEffect( () => {
        setLoadingUserProfile(true);
        RequestApis.getDevice(props.match.params.deviceId).then((response) => {
            setUser(response.data);
            setLoadingUserProfile(false);
        });
    }, []);

	return (
		<div className={classes.content}>
			<Helmet>
				<title>{document.querySelector('title').getAttribute('data-default') + ' - User Details'}</title>
			</Helmet>
            <h1>User Details</h1>
            { loadingUserProfile && <CircularProgress/> }
            { !loadingUserProfile &&
                <Formik
                    initialValues={{
                        id: user ? user.id : '',
                        email: user ? user.email : '',
                        role: user ? user.role : '',
                        firstName: user ? user.firstName : '',
                        lastName: user ? user.lastName : '',
						phoneNumber: user.phoneNumber ? user.phoneNumber.phoneNumber : '',
						callbackNumber: user.callbackNumber ? user.callbackNumber : ''
                    }}
                    validationSchema={Yup.object({
                        "firstName": Yup.string()
                            .trim().nullable()
                            .max(64, "First Name has a max limit of 64 characters."),
                        "lastName": Yup.string()
                            .trim().nullable()
							.max(64, "Last Name has a max limit of 64 characters."),
						"callbackNumber": Yup.string()
                            .trim().required("Callback Number is a required field.")   
						})
						
                    }
                    onSubmit = {
                        (values) => {
							setUserSubmitLoading(true);
							let submitAlternateIds = [];
							user.alternateIdList.forEach(
								(value) => {
									submitAlternateIds.push(value.alternateId);
								}
							)
							values.alternateIdList = submitAlternateIds;
							RequestApis.editDeviceUser(values).then(
							response => {
								setSnackBarOpen(true);
								setUserSubmitLoading(false);
								setErrorMessage(null);
							}, 
							error => {
								console.log(error.response.data.type)
								setErrorMessage(error.response.data.title);
								setUserSubmitLoading(false);
							});
                        }
                    }
                    render={formikProps => <UserDetailForm classes={classes} {...formikProps} userSubmitLoading={userSubmitLoading} user={user} errorMessage={errorMessage}/>}
                /> 
            }
            <Snackbar
                anchorOrigin={{vertical: 'top',horizontal: 'right'}}
                open={snackBarOpen}
                autoHideDuration={3000}>
				<SnackbarContent
                	action ={<IconButton key="close" aria-label="close" color="inherit" onClick={handleSnackBarClose}><CloseIcon className={classesSnackbar.icon}/></IconButton>}
                	message={<span className={classesSnackbar.message}><CheckCircleIcon className={classesSnackbar.iconVariant}/>User Details Updated!</span>}
					className={classesSnackbar.success}
                />
			</Snackbar>
		</div>
	);
}