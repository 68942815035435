import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik';
import * as Yup from "yup";
import Mye911Logo from 'assets/mye911-logo.png';
import RequestApis from 'apis/RequestApis';
import { v4 as uuidv4 } from 'uuid';
import generator from 'generate-password';
import getOS from 'utils/OsDetection/OsDetection';
import getBrowser from 'utils/BrowserDetection/BrowserDetection';
import SecurityCodeSuccessModal from './SecurityCodeSuccessModal/SecurityCodeSuccessModal';
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import './ManualLoginPage.css';

const HandleAuthorizationRedirect = props => {
    const { success, failed, deviceId, versionUpdate } = props;

    if (success)
        return <Redirect to={{pathname: "/locations/" + deviceId + "/my-location", state: { authorized: true,  versionUpdate, mye911Lite: true }}}/>
    else if (failed)
        return <Redirect to={{pathname: "/unauthorized"}} />
    else
        return <div>...Loading</div>
}

const ManualLoginForm = props => {
    const { values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            showSecurityCode,
            emailIsInvalid,
            securityCodeIsInvalid,
            setEmailIsInvalid,
            setSecurityCodeIsInvalid,
            loading } = props;

    useEffect(() => {
        setEmailIsInvalid(false);
    }, [values.email]);

    useEffect(() => {
        setSecurityCodeIsInvalid(false);
    }, [values.securityCode]);

    return (
        <form className="manual-login-form" onSubmit={handleSubmit} noValidate="novalidate">
            { showSecurityCode ?
                <div style={{ textAlign: 'center' }}>A one-time security code has been sent to
                    <b>{ ' ' + props.values.email }</b>
                    <br/>Please enter it below.</div>
                :
                <div>Welcome to MyE911<sup>&reg;</sup><br/>Please enter your email address to get started.</div>
            }
            {!showSecurityCode &&
                <div>
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        fullWidth={true}
                        autoComplete="email"
                        error={emailIsInvalid || touched.email && Boolean(errors.email)}/>
                    {emailIsInvalid &&
                    <span className="error-prompt">Sorry, we don't recognize this email address. Please try again.</span>
                    }
                    <span className="error-prompt">{touched.email ? errors.email : ""}</span>
                    <br/>
                    <br/>
                </div>
            }
            {showSecurityCode &&
                <div>
                    <TextField
                        id="securityCode"
                        label="Security Code"
                        name="securityCode"
                        onChange={handleChange}
                        value={values.securityCode}
                        onBlur={handleBlur}
                        fullWidth={true}
                        error={securityCodeIsInvalid || touched.securityCode && Boolean(errors.securityCode)}/>
                    {securityCodeIsInvalid &&
                        <span className="error-prompt">The security code you have entered is invalid or has expired.</span>
                    }
                    <span className="error-prompt">{touched.securityCode ? errors.securityCode : ""}</span>
                    <br/>
                    <br/>
                </div>
            }
            <div>
                {showSecurityCode ?
                    <Button
                        className="primary"
                        id="submit-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading || !values.securityCode}
                    >
                        Submit
                    {loading && <CircularProgress size={24} />}
                    </Button>
                    :
                    <Button
                        className="primary"
                        id="submit-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading || !values.email}
                    >
                        Next
                    {loading && <CircularProgress size={24} />}
                    </Button>
                }

            </div>
        </form>
    );
}

const ManualLoginPage = props => {
    const deviceId = localStorage.getItem('mye911_device_id');
    const refreshTokenExp = localStorage.getItem('mye911_refresh_token_exp');
    const accessToken = localStorage.getItem('mye911_access_token');

    const [showSecurityCode, setShowSecurityCode] = useState(false);
    const [secret, setSecret] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [valuesObject, setValuesObject] = useState({});
    const [loginVerificationSuccess, setLoginVerificationSuccess] = useState(false);
    const [loginVerificationFailure, setLoginVerificationFailure] = useState(false);
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [securityCodeIsInvalid, setSecurityCodeIsInvalid] = useState(false);
    const [getBrowserVersion, setGetBrowserVersion] = useState('');

    const validationSchema = Yup.object({
        email: Yup.string()
                .email('Please enter a valid email address.')
                .max(100, "Email has a max limit of 100 characters."),
        securityCode: Yup.string()
    });

    useEffect(() => {
        if (!deviceId) {
            localStorage.setItem('mye911_device_id', uuidv4());
        }

        setSecret(
            generator.generate({
                length: 12,
                numbers: true
            })
        );

        setOsVersion(getOS());

        const browserType = getBrowser().name + ' ' + getBrowser().version;
        setGetBrowserVersion(browserType);

        if (Date.now() < new Date(Number.parseInt(refreshTokenExp)) && isValidUUIDv4(deviceId)) {
            window.location.replace("/locations/" + deviceId + "/my-location?mye911Lite=true");
        }

        const footer = document.querySelector('footer');
        if (footer) {
            footer.style.display = 'none';
        }

        return () => {
            if (footer) {
                footer.style.display = 'block';
            }
        }

    }, []);

    function isValidUUIDv4(uuid) {
      const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
      return uuidPattern.test(uuid);
    }

    function handleGetSecurityCode() {
        setOpenSuccessModal(true);

        handleRegistration(valuesObject);
    }

    function handleRegistration(values) {
        localStorage.removeItem('mye911_refresh_token_id');
        RequestApis.registerV2(values).then(response => {
            setLoading(false);
            setShowSecurityCode(true);
        }).catch(error => {
            console.log(error);
            setLoading(false);
            if (error.response.data.type.includes('http://cirrus.redskytech.com/errors')) {
                setEmailIsInvalid(true);
            }
        });
    }

    function handleDeviceLogin(values) {
        if (!values.email) {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('verifyEmail');

            values.email = myParam;
        }

        RequestApis.verificationWithEmail(values).then(response => {
            RequestApis.loginDevice(values).then(response => {
                localStorage.setItem('mye911_access_token', response.data.accessToken);
                localStorage.setItem('mye911_refresh_token_id', response.data.refreshTokenInfo.id);
                localStorage.setItem('mye911_refresh_token_exp', response.data.refreshTokenInfo.exp);
                sessionStorage.setItem('mye911_lite', true);
                setLoginVerificationSuccess(true);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoginVerificationFailure(true);
                setLoading(false);
            });
        }).catch(error => {
            console.log(error);
            setLoading(false);
            if (error.response.data.type.includes('http://cirrus.redskytech.com/errors')) {
                setSecurityCodeIsInvalid(true);
            }
        });
    }

    useEffect(() => {
        if (window.location.href.includes('deviceId')) {
            const values = {};
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('deviceId');
            const mySecret = urlParams.get('secret');

            values.deviceId = myParam;
            values.secret = mySecret;

            RequestApis.loginDevice(values).then(response => {
                localStorage.setItem('mye911_access_token', response.data.accessToken);
                localStorage.setItem('mye911_refresh_token_id', response.data.refreshTokenInfo.id);
                localStorage.setItem('mye911_refresh_token_exp', response.data.refreshTokenInfo.exp);
                localStorage.setItem('mye911_device_id', myParam);
                sessionStorage.setItem('mye911_lite', true);
                setLoginVerificationSuccess(true);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoginVerificationFailure(true);
                setLoading(false);
            });
        }
    }, []);

    return (
        loginVerificationSuccess ?
        <HandleAuthorizationRedirect
            success={loginVerificationSuccess}
            failed={loginVerificationFailure}
            deviceId={deviceId}
        />
        :
        <div>
            <div className="manual-login-page">
                <Helmet>
                    <title>{document.querySelector('title').getAttribute('data-default') + ' - Log In'}</title>
                </Helmet>
                <span id="logo"></span>
                <Card>
                    <img src={Mye911Logo} alt="MyE911 Logo" width={100}/>
                    <br/>
                    <Formik
                        initialValues={{
                            email: '',
                            securityCode: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit = {
                            (values) => {
                                values.deviceId = deviceId;
                                values.secret = secret;
                                values.osVersion = osVersion;
                                values.browserVersion = getBrowserVersion;
                                setLoading(true);
                                if (values.securityCode) {
                                    handleDeviceLogin(values);
                                } else {
                                    handleRegistration(values);
                                }
                                setValuesObject(values);
                            }
                        }
                        render={props => <ManualLoginForm
                                            setSecurityCodeIsInvalid={setSecurityCodeIsInvalid}
                                            setEmailIsInvalid={setEmailIsInvalid}
                                            securityCodeIsInvalid={securityCodeIsInvalid}
                                            emailIsInvalid={emailIsInvalid}
                                            loading={loading}
                                            showSecurityCode={showSecurityCode}
                                            {...props}/>}
                    />
                    {showSecurityCode &&
                        <small>Didn't receive the security code? Check your Junk/Spam folder.<br/>Still need another code? Click <Button id="get-security-code-button" onClick={handleGetSecurityCode}>here</Button>.</small>
                    }
                </Card>
            </div>
            <SecurityCodeSuccessModal
                setOpenSuccessModal={setOpenSuccessModal}
                openSuccessModal={openSuccessModal}
            />
        </div>
    )
}

export default ManualLoginPage;
