import { axiosInstance } from './api';

const TOKEN_EXCHANGE = '/auth-service/token/exchange';
const REGISTER = '/auth-service/registration';
const VERIFICATION = '/auth-service/registration/verify';
const MY_LOCATIONS = '/geography-service/locations/search';
const MYE911_LOCATIONS = '/mye911-service/locations/mye911/';
const CORPORATE_LOCATIONS = '/mye911-service/locations/company/';
const PERSONAL_LOCATIONS = '/mye911-service/locations/deviceUser/';
const SET_LOCATIONS = '/mye911-service/locations/';
const SET_LOCATIONS_GEO = '/geography-service/locations/';
const SET_LOCATIONS_PARSED = '/geography-service/locations/parsed';
const EDIT_LOCATIONS = '/mye911-service/locations/edit/';
const GET_DEVICE = '/mye911-service/deviceUser/device/';
const GET_CLIENTDOWNLOADS = '/admin-service/client/downloads';
const LOGIN_DEVICE = '/auth-service/login/device';
const EDIT_USER = '/admin-service/deviceUser'

export default class RequestApis {
    static tokenExchange(exchangeToken) {
        const payload = exchangeToken;
        return axiosInstance.post(TOKEN_EXCHANGE, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' }
        });
    }

    static register(credentials) {
        const payload = {
            email: credentials.email,
            deviceId: credentials.deviceId
        };
        return axiosInstance.post(REGISTER, payload);
    }
    
    static registerV2(credentials) {
        const payload = {
            email: credentials.email,
            deviceId: credentials.deviceId,
            platformInfoTO: {
                osVersion: credentials.osVersion,
                type: 'WEB',
                model: credentials.browserVersion
            },
            secret: credentials.secret,
        };
        return axiosInstance.post(REGISTER + '/v2', payload);
    }

    static verification(verificationKey) {
        return axiosInstance.get(`${VERIFICATION}/${verificationKey}`);
    }

    static verificationWithEmail(data) {
        return axiosInstance.get(`${VERIFICATION}/${data.securityCode}?email=${encodeURIComponent(data.email)}`);
    }

    static getMyLocations(pattern, deviceId) {
        return axiosInstance.get(MY_LOCATIONS, {
            params: {
                pattern,
                deviceId
            }
        });
    }
    
    static getCorporateLocations(deviceId) {
        return axiosInstance.get(CORPORATE_LOCATIONS, {
            params: {
                deviceId
            }
        });
    }

    static getMyE911Locations(deviceId, type, searchTerm, page, pageSize, sortBy, direction) {
        return axiosInstance.get(MYE911_LOCATIONS, {
            params: {
                deviceId,
                type,
                searchTerm,
                page,
                pageSize,
                sortBy,
                direction 
            }
        });
    }
    
    static getPersonalLocations(deviceId) {
        return axiosInstance.get(PERSONAL_LOCATIONS, {
            params: {
                deviceId
            }
        });
    }

    static setMyLocations(deviceId, locationId) {
        return axiosInstance.put(`${SET_LOCATIONS}?deviceId=${deviceId}&locationId=${locationId}`);
    }
    
    static getDevice(deviceId) {
        return axiosInstance.get(`${GET_DEVICE}${deviceId}`);
    }

    static createLocation(location) {
        const payload = {
            name: location.name,
            info: location.info,
            fullAddress: location.fullAddress,
            deviceId: location.deviceId
        };
        return axiosInstance.post(SET_LOCATIONS_GEO, payload);
    }
    
    static createLocationParsed(location) {
        const payload = {
            name: location.name,
            info: location.info,
            deviceId: location.deviceId,
            parsedAddress: {
                houseNumber: location.address.houseNumber,
                houseNumberExtension: location.address.houseNumberExtension,
                streetName: location.address.streetName,
                streetType: location.address.streetType,
                zipCode: location.address.zipCode,
                city: location.address.city,
                country: location.address.country,
                state: location.address.state,
                prefixDirectional: location.address.prefixDirectional,
                postDirectional: location.address.postDirectional
              }
        };
        return axiosInstance.post(SET_LOCATIONS_PARSED, payload);
    }
    
    static editLocationParsed(location, deviceUserId) {        
        const payload = {
            id: location.id,
            name: location.name,
            info: location.info,
            deviceUserId: deviceUserId,
            parsedAddress: {
                houseNumber: location.address.houseNumber,
                houseNumberExtension: location.address.houseNumberExtension,
                streetName: location.address.streetName,
                streetType: location.address.streetType,
                zipCode: location.address.zipCode,
                city: location.address.city,
                country: location.address.country,
                state: location.address.state,
                prefixDirectional: location.address.prefixDirectional,
                postDirectional: location.address.postDirectional
              }
        };
        return axiosInstance.put(SET_LOCATIONS_PARSED, payload);
    }

    static editLocation(location, deviceUserId) {
        const payload = {
            id: location.id,
            name: location.name,
            info: location.info,
            fullAddress: location.fullAddress,
            deviceUserId: deviceUserId
        };
        return axiosInstance.put(SET_LOCATIONS_GEO, payload);
    }

    static deleteLocation(id, deviceId) {
        return axiosInstance.delete(`${SET_LOCATIONS}${id}?deviceId=${deviceId}`);
    }

    static getClientDownloads() {
        return axiosInstance.get(GET_CLIENTDOWNLOADS);
    }

    static loginDevice(credentials) {
        const payload = {
            secret: credentials.secret,
            deviceId: credentials.deviceId
        };
        return axiosInstance.post(LOGIN_DEVICE, payload);
    }

    static editDeviceUser(payload) {
        return axiosInstance.put(EDIT_USER, payload);
    }
}
