import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RequestApis from 'apis/RequestApis';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddressAutoSuggest from '../AddressAutoSuggest/AddressAutoSuggest';
import AlternateAddress from 'components/LocationsPage/ManagePage/AlternateAddress/AlternateAddress';
import Grid from '@material-ui/core/Grid';

import './EditLocationModal.css';

const useStyles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
    right: '7px',
    top: '33px'
  },
  tooltip: {
    fontSize: '14px'
  }
}));

const zipRegexes = {
  US: /^\d{5}?$/,
  CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
};

export default function EditLocationModal(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [ambiguousAddressResults, setAmbiguousAddressResults] = useState([]);
  const [isAmbiguous, setIsAmbiguous] = useState(false);
  const [addressesValue, setAddressesValue] = useState('');
  const [addressFieldValue, setAddressFieldValue] = useState('');
  const [nameFieldError, setNameFieldError] = useState(false);
  const [addressFieldError, setAddressFieldError] = useState(false);
  const [isAlternateAddress, setIsAlternateAddress] = useState(false);

  const addressRowData = props.row.original.address.normalizedAddress;
  const classes = useStyles();
  const deviceId = props.deviceId;
  const deviceUserId = props.getDeviceInfo.id;
  const infoRequired = props.getDeviceInfo.company && props.getDeviceInfo.company.requireLocationInfo;

  const validationSchema = Yup.object({
    name: Yup.string("Enter a name")
            .required('Location Name is a required field.')
            .trim().max(50, "Location Name has a max limit of 50 characters."),
    address: isAlternateAddress ? 
            Yup.string("Enter an address") : 
            Yup.string("Enter an address")
              .required('Address is a required field.')
              .trim(),
    info: infoRequired ?
            Yup.string("Enter info")
                  .required('Location Information is a required field.')
                  .trim().max(20, "Location Information has a max limit of 20 characters.")
            :
            Yup.string("Enter info")
                  .trim().max(20, "Location Information has a max limit of 20 characters."),
    houseNumber: isAlternateAddress ?
            Yup.string("Enter a street address")
            .trim()
            .required("House Number is a required field.")
            .max(10, "House Number has a max limit of 10 characters.")
            .matches( /^[^#]+$/, 'Number sign character is not allowed')
            :
            Yup.string("Enter a street address")
            .trim(),
    houseNumberExtension: isAlternateAddress ?
            Yup.string("Enter a street address")
            .trim()
            .max(5, "House Number Extension has a max limit of 5 characters.") :
            Yup.string("Enter a street address")
            .trim(),
    prefixDirection: 
          Yup.string("Enter a prefix direction")
          .trim(),
    street: isAlternateAddress ?
          Yup.string("Enter a street address")
          .trim()
          .required("Street Name is a required field.")
          .max(50, "Street Name has a max limit of 50 characters.")
          :
          Yup.string("Enter a street address")
          .trim(),
    streetType: Yup.string("Enter a street type").trim().max(4, "Street Type has a max limit of 4 characters."),
    postDirection: Yup.string("Enter a post direction").trim(),
    city: isAlternateAddress ?
          Yup.string("Enter a city")
          .trim()
          .required("City is a required field.")
          .max(255, "City has a max limit of 255 characters.")
          :
          Yup.string("Enter a city")
          .trim(),
    stateProvince: isAlternateAddress ?
          Yup.string("Select a state/province")
          .trim()
          .required("State/Province is a required field.")
          :
          Yup.string("Select a state/province")
          .trim(),
    zip: isAlternateAddress ?
          Yup.string("Enter a ZIP/Postal Code")
          .trim()
          .when("country", {
                  is: "US",
                  then: Yup.string().max(5, "ZIP/Postal Code has a max limit of 5 characters.")
          })
          .when("country", {
                  is: "CA",
                  then: Yup.string().max(7, "ZIP/Postal Code has a max limit of 7 characters.")
          })
          .required('ZIP/Postal code is a required field.')
          .transform(value => value.toUpperCase())
          .when('country', (country, schema) => {
                  return schema.matches(
                          zipRegexes[country],
                          `Not a valid ZIP/Postal Code.`
                  );
          })
          :
          Yup.string("Enter a ZIP/Postal Code")
          .trim(),
    country: isAlternateAddress ? 
          Yup.string("Select a country")
          .trim()
          .required("Country is a required field.")
          :
          Yup.string("Select a country")
          .trim(),
    addressesValue: isAmbiguous && addressesValue.length === 0 ?
          Yup.string()
          .required()
          :
          Yup.string()
});

  function handleClose() {
    props.setOpen(false);
  }

  function removeDuplicateValues(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  function errorHandler(error) {
    if (error.response && error.response.status >= 500) {
      setErrorMessage('The server is having issues. Please try again later.');
    } else if (error.response) {
      setErrorMessage(error.response.data.title);
    } else {
      setErrorMessage('The server is having issues. Please try again later.');
    }
  }

  function handleSubmit(values) {
    if (!isAmbiguous && !isAlternateAddress) {
      setLoading(true);
      RequestApis.editLocation(values, deviceUserId).then(
        response => {
          setLoading(false);
          if (response.data.ambiguous) {
              setIsAmbiguous(true);
              setAmbiguousAddressResults(removeDuplicateValues(response.data.addressList, 'normalizedAddress'));
              // setErrorMessage('Multiple addresses found. Please select the appropriate one.');
          } else {
            handleClose();
            props.setRefreshList(true);
          }
        }
      ).catch(error => {
        setNameFieldError(false);
        setAddressFieldError(false);
        if (error.response && error.response.data.fieldName === 'name') {
          setNameFieldError(true);
        }

        if (error.response && error.response.data.fieldName === 'fullAddress') {
          setAddressFieldError(true);
        }
        setLoading(false);
        errorHandler(error);
      });
    } else {
      setLoading(true);
      RequestApis.editLocationParsed(values, deviceUserId).then(response => {
          setLoading(false);
          if(response.data.ambiguous){
            setIsAmbiguous(true);
            setAmbiguousAddressResults(removeDuplicateValues(response.data.addressList, 'normalizedAddress'));
            //setErrorMessage('We found this address, select to proceed.');
          }
          else{
          handleClose();
          props.setRefreshList(true);
          }
      }).catch(error => {
        setLoading(false);
        errorHandler(error);
      });
    }
  }

  function handleAlternateAddress(event) {
    setIsAlternateAddress(!isAlternateAddress);
  }

  function handleRadioChange(event) {
    setAddressesValue(event.target.value);
  }
  
  const rowAddress = props.row.original.address;
  return (
    <div>
      <Dialog
        className="edit-location-modal"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        { loading &&
           <div className="circular-progress">
             <CircularProgress/>
           </div>
        }
		<DialogTitle id="alert-dialog-title">Edit Location</DialogTitle>
        { errorMessage && !nameFieldError && !addressFieldError &&  <div className="error-message-text">{errorMessage}</div> }
        <DialogContent>
            <Formik
                initialValues={{
                    name: props.row.original.name,
                    address: '',
                    info: infoRequired ? props.row.original.info : props.row.original.info ? props.row.original.info : '', 
                    houseNumber: rowAddress.houseNumber ?  rowAddress.houseNumber : '',
                    houseNumberExtension: rowAddress.houseNumberExtension ? rowAddress.houseNumberExtension : '',
                    prefixDirection: rowAddress.prefixDirectional ? rowAddress.prefixDirectional : '',
                    streetType: rowAddress.streetType ? rowAddress.streetType : '',
                    postDirection: rowAddress.postDirectional ? rowAddress.postDirectional : '',
                    street: rowAddress.streetName,
                    city: rowAddress.city,
                    stateProvince: rowAddress.state,
                    zip: rowAddress.zipCode,
                    country: rowAddress.country === 'USA' || rowAddress.country === 'US' ? 'US' : 'CA'
                }}
                onSubmit={(values) => {
                    values.id = props.row.original.id;
                    values.fullAddress = addressFieldValue;
                    values.deviceId = props.deviceId;

                    if(isAlternateAddress) {
                      values.address = {
                        houseNumber: values.houseNumber,
                        houseNumberExtension: values.houseNumberExtension,
                        streetName: values.street,
                        streetType: values.streetType,
                        zipCode: values.zip,
                        city: values.city,
                        country: values.country,
                        state: values.stateProvince,
                        prefixDirectional: values.prefixDirection,
                        postDirectional: values.postDirection
                      }
                    }

                    if (addressesValue) {
                      values.address = JSON.parse(addressesValue);
                    }

                    handleSubmit(values);
                }}
                validationSchema={validationSchema}
                render={props => (
                    <form onSubmit={props.handleSubmit} className="edit-location-form">
                        <TextField
                            autoComplete="off"
                            id="name"
                            label="*Location Name"
                            margin="normal"
                            variant="outlined"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name}
                            name="name"
                            error={(props.touched.name && Boolean(props.errors.name)) || (props.errors.name && Boolean(props.errors.name.includes('max'))) || (nameFieldError)}
                          />
                          {(props.touched.name && Boolean(props.errors.name)) || nameFieldError ? '' :
                            <span style={{ color: '#456a7f', fontStyle: 'italic' }}>Examples: Home, Cubicle, XYZ Cafe</span>
                          }
                        <br/>
                        <span className="error-prompt">{nameFieldError && 'The location name provided already exists.'}</span>
                        <span className="error-prompt">{(props.touched.name && Boolean(props.errors.name)) || (props.errors.name && Boolean(props.errors.name.includes('max'))) ? props.errors.name : ''}</span>
                        {isAlternateAddress && ambiguousAddressResults.length === 0 ? 
                            <AlternateAddress {...props}/> : ''
                        }
                        <br></br>
                        { !isAlternateAddress && ambiguousAddressResults.length === 0 ?
                          <div>
                            <AddressAutoSuggest
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              address={addressRowData}
                              deviceId={deviceId}
                              setAddressFieldValue={setAddressFieldValue}
                              error={(props.touched.address && Boolean(props.errors.address)) || (addressFieldError)}
                              {...props} />
                            <span className="error-prompt">{addressFieldError && 'The address provided is not properly formatted.'}</span>
                          </div> :''
                        }
                        { ambiguousAddressResults.length > 0 ? 
                            <RadioGroup
                              aria-label="addresses"
                              name="addresses"
                              value={addressesValue}
                              onChange={handleRadioChange}>
                            {
                              ambiguousAddressResults.map(address => {
                                return <FormControlLabel key={address.normalizedAddress} value={JSON.stringify(address)} control={<Radio color="primary" />} label={address.normalizedAddress} />
                              })
                            }
                            </RadioGroup> : ''
                        }
                        { !isAlternateAddress && !addressFieldValue && <span className="error-prompt">{props.touched.address ? props.errors.address : ""}</span>}
                        {!isAlternateAddress && ambiguousAddressResults.length === 0 ? 
                          <span style={{ color: '#456a7f', fontStyle: 'italic', marginTop: '-23px' }}>Example: 1234 1/2 N Main St, Metropolis, IL 12345</span> : <span> </span>
                        }
                        <span className="error-prompt">{ addressesValue.length === 0 && ambiguousAddressResults.length > 0 ? 
                          <div>{ ambiguousAddressResults.length > 1 ?
                          'Multiple addresses found. Please select the appropriate one in order to proceed.' :
                          'We found this address. Please select it in order to proceed.'}
                          </div> : <div> </div> }
                        </span>
                        {ambiguousAddressResults.length === 0 ? 
                          <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Button onClick={handleAlternateAddress} style={{ margin: '5px 0 0 auto', color: '#1b536a', textTransform: 'capitalize' }} variant="outlined" color="primary">Alternate address view</Button>
                          </Grid> : ''
                        }
                        <div className="info-wrapper">
                          <TextField
                            autoComplete="off"
                            id="info"
                            label={infoRequired ? '*Location Information' : 'Location Information'}
                            margin="normal"
                            variant="outlined"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.info ? props.values.info : ""}
                            name="info"
                            error={(props.touched.info && Boolean(props.errors.info)) || (props.errors.info && Boolean(props.errors.info.includes('max')))}
                            />
                            <Tooltip title="This information will be sent with your 911 call." classes={{
                                tooltip: classes.tooltip
                            }}>
                                <Info className={classes.icon}/>
                            </Tooltip>
                            {props.touched.info && Boolean(props.errors.info) ? '' : 
                            <span style={{ color: '#456a7f', fontStyle: 'italic' }}>Examples: Apt 657, 1st Floor, Unit 1500</span>
                          }
                        </div>
                        <span className="error-prompt">{(props.touched.info && Boolean(props.errors.info)) || (props.errors.info && Boolean(props.errors.info.includes('max'))) ? props.errors.info : ''}</span>
                        <br/>
                        <Button className="warning" onClick={handleClose}>Cancel</Button>
                        <Button className="primary" type="submit">Save</Button>
                    </form>
                )}
            />
        </DialogContent>
      </Dialog>
    </div>
  );
}