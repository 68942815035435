import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


function DeviceUsersListTable(props) {
    const buildTableColumns = [
        {
            Header: "Device User IDs",
            id: "deviceUserIds",
            resizable: false,
            Cell: row => {
                return <div title={row.original.alternateId}> {row.original.alternateId}</div>
            }
        },
    ];

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');
        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, []);

    return (
        <ReactTable
            data={props.value}
            resizable={false}
            sortable={false}
            pageSize={50}
            minRows={props.value && props.value.length > 0 ? 0 : 5}
            className="-striped -highlight"
            showPaginationBottom={false}
            columns={buildTableColumns}
            style={{margin: '40px 0px 20px', maxHeight: '300px'}}
        />
    );
}

export default (DeviceUsersListTable);