import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import './SuccessMessageModal.css';

export default function SuccessMessageModal(props) {
  function handleClose() {
    props.setOpenSuccessModal(false);
  }

  return (
    <div>
      <Dialog
        open={props.openSuccessModal}
        onClose={handleClose}
        aria-labelledby="success-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="success-dialog-title">Success!</DialogTitle>
        {props.alternateMessage ? 
          <div style={{
            width: '80%',
            padding: '0 40px'
          }}>
            The location "{props.deviceInfo && props.deviceInfo.currentEmergencyResponseLocation && props.deviceInfo.currentEmergencyResponseLocation.name ? props.deviceInfo.currentEmergencyResponseLocation.name : ''}" was successfully set as your current location. You can view this on the 'My Location' page.
          </div>
          :
          <div style={{
            width: '80%',
            padding: '0 40px'
          }}>
            The location "{props.deviceInfo && props.deviceInfo.currentEmergencyResponseLocation && props.deviceInfo.currentEmergencyResponseLocation.name ? props.deviceInfo.currentEmergencyResponseLocation.name : ''}" was successfully added as a Personal Location. You can view it on the 'Manage Locations' page.
          </div>
        }
        <Button className="primary success-button" type="button" onClick={handleClose}>Close</Button>
      </Dialog>
    </div>
  );
}