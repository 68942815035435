import React, { useEffect, useState } from 'react';
import { loadResources } from 'apis/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import CirrusInfoContext from 'utils/CirrusInfoContext/CirrusInfoContext';

export default function Startup(props) {
    const [cirrusInfoLoaded, setCirrusInfoLoaded] = useState(false);
    const [cirrusInfoObject, setCirrusInfoObject] = useState({});
    
    useEffect( () => { 
        loadResources(setCirrusInfoLoaded, setCirrusInfoObject);
    }, []);

    return cirrusInfoLoaded ? 
        <CirrusInfoContext.Provider value={cirrusInfoObject}>
            {props.children}
        </CirrusInfoContext.Provider>
        : 
        <div><LinearProgress /><p>Loading Resources...</p></div>;
}

