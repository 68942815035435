import React from 'react';
import Ambulance from '../../assets/ambulance.png';
import { Helmet } from "react-helmet";
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      this.setState({
        errorInfo: errorInfo
      })
    }
    
    render() {
      if (this.state.errorInfo) {
        return (
          <div className="error-page">
              <Helmet>
                  <title>{document.querySelector('title').getAttribute('data-default') + ' - Error'}</title>
              </Helmet>
            <div className="section">
              <img src={Ambulance} alt="Ambulance"/>
              <h1>Oops!</h1>
              <p>Apologies for the inconvenience, but an unexpected error occurred. We will be looking into what caused the error, but for now please try <span onClick={() => window.location.reload()}>refreshing</span> the page.</p>
            </div>
          </div>
        );
      }
      return this.props.children;
    }  
  }

  export default ErrorBoundary;