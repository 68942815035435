import { createMuiTheme, createStyles } from '@material-ui/core/styles';

/**
 * Used to update the color of the input label for error messages
 * and the bottom border lines
 */
export const Five9Theme = createMuiTheme({
    overrides: {
        MuiInputLabel: createStyles({
            root: {
                '&$error': {
                    color: 'rgba(255, 41, 52, 1.0) !important'
                }
            },
            error: {}
        }),
        MuiOutlinedInput: createStyles({
            root: {
                '&$error $notchedOutline': {
                    borderColor: 'rgba(255, 41, 52, 1.0) !important'
                }
            },
            error: {}
        }),
        MuiButton: createStyles({
            root: {
                '&$disabled$containedPrimary': {
                    // background: 'rgba(0, 0, 0, 0.12) !important'
                },
                '&$containedPrimary': {
                    background: 'rgba(36, 76, 222, 1.0) !important'
                },
            },
            disabled: {}
        }),
    }
});