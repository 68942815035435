import axios from 'axios';

export const axiosInstance = axios.create({
    headers: {
        'Cache-control': 'no-store',
        'Pragma': 'no-cache',
        'Expires': 0
    },
    timeout: 15000
});

export const loadResources = (setCirrusInfoLoaded, setCirrusInfoObject) => { 
    if(process.env.NODE_ENV === 'development') {
        // set info object if needed
        setCirrusInfoLoaded(true);
    }
    else {
        axios.get(process.env.PUBLIC_URL + '/cirrus.info').then(
            response => {
                const infoObject = {};
                response.data.split('\n').forEach(row => {
                    const temp = row.split('=');
                    infoObject[temp[0]] = temp[1];
                });
    
                setCirrusInfoObject(infoObject);
    
                if(process.env.NODE_ENV === 'production') {
                    const portalUrl = infoObject['cirrus.api.url'];
                    axiosInstance.defaults.baseURL = portalUrl;
                    setCirrusInfoLoaded(true);
                } else {
                    setCirrusInfoLoaded(true);
                }
            }
        )
    }
}

const REFRESH_ENDPONT = '/auth-service/token/refresh';

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('mye911_access_token');
        const authHeader = `Bearer ${accessToken}`;
        config.headers.common['Authorization'] = authHeader

        return config;            
    },
    error => {
        console.log(error);
    }
);

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error, token = null) => {
    failedQueue.forEach(promise => {
        if(error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });
    failedQueue = [];
}

axiosInstance.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    const originalRequest = error.config;
    console.log(error);
    if (status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({resolve, reject});
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return err
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const refreshToken = localStorage.getItem('mye911_refresh_token_id');
        const config = { headers: { 'Content-Type': 'application/json'}};
        return new Promise( (resolve, reject) =>
            axiosInstance.post(REFRESH_ENDPONT, JSON.stringify(refreshToken), config)
                .then( 
                    (response) => {
                        localStorage.setItem('mye911_access_token', response.data.accessToken);
                        localStorage.setItem('mye911_access_token_exp', response.data.refreshTokenInfo.exp);
            
                        axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
                        processQueue(null, response.data.accessToken);
                        originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
                        resolve(axios(originalRequest));
                    },
                    (error) => {
                        const status = error.response ? error.response.status : null
                        if (status === 401) {
                            window.history.pushState(null, null, '/unauthorized');
                            reject(error);
                        }
                        processQueue(error, null);
                        reject(error);
                    }
                ).then(() => { isRefreshing = false })
        );
    }
    return Promise.reject(error);
});