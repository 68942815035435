import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from 'components/LoginPage/LoginPage';
import ManualLoginPage from 'components/ManualLoginPage/ManualLoginPage';
import RegisterPage from 'components/RegisterPage/RegisterPage';
import VerificationPage from 'components/VerificationPage/VerificationPage';
import Unauthorized from 'components/UnauthorizedPage/UnauthorizedPage';
import AuthorizedRoute from 'utils/AuthorizedRoute/AuthorizedRoute';
import LocationsPage from 'components/LocationsPage/LocationsPage';
import moment from 'moment';
import { Helmet } from "react-helmet";
import DefaultFavicon from "../../assets/favicon.ico";
import DarkFavicon from "../../assets/favicon-dark.ico";
import Five9Favicon from 'assets/five9-favicon.ico';
import history from '../../utils/History/History';
import './App.css';

const App = (props) => {
	const currentYear = moment().year();

	const [isDarkMode, setIsDarkMode] = useState(false);
	
	useEffect(() => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setIsDarkMode(true);
        }
    }, [history.location.pathname]);

	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (event.matches) {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    });

	return (
	<div className="container">
		{localStorage.getItem('skin') === 'five9' ? 
                <Helmet>
                    <title>Five9</title>
                    <link rel="shortcut icon" href={Five9Favicon}></link>
                </Helmet>
                : isDarkMode ?
				<Helmet>
                    <link rel="shortcut icon" href={DarkFavicon}></link>
                </Helmet> 
				:
				<Helmet>
					<link rel="shortcut icon" href={DefaultFavicon}></link>
                </Helmet>
            }
		<Switch>
			<Route path="/" exact component={ManualLoginPage}/>
			<Route path="/login/:deviceId" component={LoginPage}/>
			<Route path="/register" component={RegisterPage}/>
			<Route path="/verification" component={VerificationPage}/>
			<AuthorizedRoute path="/locations/:deviceId" component={LocationsPage} {...props}/>
			<Route path="/unauthorized" component={Unauthorized}/>
			<Route path="*">
				<Redirect to="/" />
			</Route>
		</Switch>
		<footer>©{currentYear} RedSky Technologies. All Rights Reserved. <a href="https://www.redskye911.com/privacy-policy" target="_blank" style={{ textDecoration: 'underline', color: '#000' }}>Privacy Policy</a></footer>
	</div>
	);
}

export default App;
