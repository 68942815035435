import React, { useEffect, useState } from 'react';
import Error from '@material-ui/icons/Error'; 
import { makeStyles } from '@material-ui/core/styles';
import RequestApis from 'apis/RequestApis';
import Link from '@material-ui/core/Link';
import OsDetection from 'utils/OsDetection/OsDetection';
import DOMPurify  from 'dompurify';

const useStyles = makeStyles(theme => ({
    errMsg: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '3px',
        color: '#AF9549',
        backgroundColor: '#FFF3CD'
    }
}));

const VersionUpdateBanner = props => {
	const classes = useStyles();
    const [downloadUrl, setDownloadUrl] = useState(null);

    useEffect( () => {
        RequestApis.getClientDownloads().then(
            response => {
                response.data.forEach(clientDownload => {
                    if(OsDetection() === 'Windows' && clientDownload.clientName.includes('MyE911') && clientDownload.osName.includes('Windows')) {
                        setDownloadUrl(clientDownload.downloadLink);
                    } else if(OsDetection() === 'Mac OS' && clientDownload.clientName.includes('MyE911') && clientDownload.osName.includes('Mac OS X')) {
                        setDownloadUrl(clientDownload.downloadLink);
                    }
                });
            }
        )
    },[]);

    return (
        <div>
            { downloadUrl && 
                <div className={classes.errMsg}>
                    <Error/>
                    <span>A new version of MyE911 is available.  </span>
                    <Link href={DOMPurify.sanitize(downloadUrl)} color="inherit">Click here to download</Link>
                </div> 
            }
        </div>
    );
}

export default VersionUpdateBanner;