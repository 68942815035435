import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField } from '@material-ui/core';
import RequestApis from 'apis/RequestApis';
import Exclamation from 'assets/exclamation_icon.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import SideNav from 'utils/SideNav/SideNav';
import Logo from 'assets/redsky.svg';
import { Helmet } from "react-helmet";

import './RegisterPage.css';

const validationSchema = Yup.object({
    email: Yup.string("Enter an email")
            .trim().email().max(100, "Email has a max limit of 100 characters.")
});

const Register = props => {
    const [successMessageFlag, setSuccessMessageFlag] = useState(false);
    const [errorMessageFlag, setErrorMessageFlag] = useState(false);
    const [loading, setLoading] = useState(false);

    const urlParams = new URLSearchParams(props.location.search);
    const deviceId = urlParams.get('deviceId');

    function handleFormSubmit(values) {
        setLoading(true);
        RequestApis.register(values).then(() => {
            setLoading(false);
            setSuccessMessageFlag(true);
            setErrorMessageFlag(false);
        }).catch(() => {
            setLoading(false);
            setErrorMessageFlag(true);
            setSuccessMessageFlag(false);
        })
    }

    return (
        <div className="main-content">
            <Helmet>
                <title>{document.querySelector('title').getAttribute('data-default') + ' - Register'}</title>
            </Helmet>
            <SideNav hideSideLinks {...props} />
            <div className="register-page">
                { loading &&
                    <div className="circular-progress">
                        <CircularProgress/>
                    </div>
                }
                <div className="logo-wrapper">
                    <img className="logo" src={Logo} alt="RedSky Logo" />
                </div>
                <h1>Please Register Your Device</h1>
                <img src={Exclamation} alt="Exclamation Icon" />
                <h3>Please register your device with your email address</h3>
                <p>Thanks for using Redsky MyE911. To ensure that 9-1-1 calls are routed correctly. It is required by your company to register your device.</p>
                { !successMessageFlag ? 
                    <Formik
                        onSubmit={(values) => {
                            values.deviceId = deviceId;
                            handleFormSubmit(values);
                        }}
                        validationSchema={validationSchema}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <TextField
                                    id="email"
                                    placeholder="Your Email Address"
                                    margin="normal"
                                    constiant="outlined"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    name="email"
                                    required={true}
                                />
                                <Button className="primary" type="submit">Register</Button>
                                <span className="error-message">{props.errors.email && <div>{props.errors.email}</div>}</span>
                            </form>
                        )}
                    /> : '' 
                }
                { successMessageFlag && !errorMessageFlag ? 
                    <div className="form-success-message">Email submission successfully<br/><br/>Please check your email to confirm your device registration. Email will expire in 24 hours. <br/><b>Please close browser</b></div> 
                    : '' 
                }
                { errorMessageFlag && !successMessageFlag ? 
                    <div className="form-error-message">Email submission error<br/><br/>Unfortunately, there was an issue with processing your email. Please try again with the valid email that was registered. If this issues persists, please contact your company administrator to verify that your account has been successfully registered.</div> 
                    : '' 
                }
                { props.location.state && props.location.state.verificationStatus && !successMessageFlag && !errorMessageFlag ? 
                    <div className="form-error-message">Verification error<br/><br/>Unfortunately, that verification email is no longer valid or has expired. Please register again!
                    </div>
                    : ''
                }
            </div>
        </div>
    );
}

export default Register;