import React, { useEffect, useState } from 'react';
import RequestApis from 'apis/RequestApis';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import './VerificationPage.css';

const HandleVerificationResults = props => {
    const { success, failed } = props;

    if (success)
			return (               
				<div className="verification-message success">
						<p>Congratulations!</p>
						<p>You have successfully verified your email-address. Your device will now be in sync. <br/><b>Please close browser</b></p>
				</div> 
			);
    else if (failed)
			return <Redirect to={{pathname: "/unauthorized"}} />
    else
			return (
				<div className="verification-message">
					<p>Verifying request...</p>
					<p>This may take a few seconds...</p>
				</div>
			)
}

const Verification = props => {
    const urlParams = new URLSearchParams(props.location.search);
    const verificationId = urlParams.get('verificationId');

    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        RequestApis.verification(verificationId).then(() => {
            setLoading(false);
            setVerificationSuccess(true);
        }).catch(() => {
            setLoading(false);
            setVerificationFailed(true);
        })
    }, [])
    
    return (
        <div className="verification-page">
            <Helmet>
                <title>{document.querySelector('title').getAttribute('data-default') + ' - Verification'}</title>
            </Helmet>
            { loading &&
                <div className="circular-progress">
                    <CircularProgress/>
                </div>
            }
            <HandleVerificationResults 
                success={verificationSuccess}
                failed={verificationFailed} 
						/>
        </div>
    );
}

export default Verification;