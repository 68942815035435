import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import './SecurityCodeSuccessModal.css';

export default function SecurityCodeSuccessModal(props) {
  function handleClose() {
    props.setOpenSuccessModal(false);
  }

  return (
    <div>
      <Dialog
        open={props.openSuccessModal}
        onClose={handleClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <div id="success-dialog-title"></div>
        <div style={{
          padding: '0 40px'
        }}>
          The one-time security code has been sent!
        </div>
        <Button className="primary success-button" type="button" onClick={handleClose}>Close</Button>
      </Dialog>
    </div>
  );
}