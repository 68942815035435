import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { states, provinces, countries, direction } from 'utils/stateAndCountryData';

function AlternateAddress(props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        modalLoading } = props;

    useEffect(() => {
        const houseNumber = document.getElementById('houseNumber');

        if (houseNumber) {
            houseNumber.focus();
        }

    }, []);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <TextField
                        style={{ minWidth: 300 }}
                        id="houseNumber"
                        label="*House Number"
                        margin="normal"
                        name="houseNumber"
                        type="houseNumber"
                        onChange={handleChange}
                        value={values.houseNumber}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={touched.houseNumber && Boolean(errors.houseNumber)}
                        variant="outlined"/> 
                        <br/>
                        {(props.touched.houseNumber && props.errors.houseNumber) &&
                            <span className="error-prompt">{props.touched.houseNumber ? props.errors.houseNumber : ""}</span>
                        }
                        {!(props.touched.houseNumber && props.errors.houseNumber) && <span style={{ color: '#456a7f', fontStyle: 'italic' }}>Example: 1234</span> }  
                </div> 
                <div>
                    <TextField
                        style={{ minWidth: 300 }}
                        id="houseNumberExtension"
                        label="House Number Extension"
                        margin="normal"
                        name="houseNumberExtension"
                        type="houseNumberExtension"
                        onChange={handleChange}
                        value={values.houseNumberExtension}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={touched.houseNumberExtension && Boolean(errors.houseNumberExtension)}
                        variant="outlined"/> 
                        <br/>
                        {(props.touched.houseNumberExtension && props.errors.houseNumberExtension) && 
                            <span className="error-prompt" >{props.touched.houseNumberExtension ? props.errors.houseNumberExtension : ""}</span>
                        }
                        {!(props.touched.houseNumberExtension && props.errors.houseNumberExtension) && <span style={{ color: '#456a7f', fontStyle: 'italic' }}>Examples: 1/2, A, B</span> }
                </div>
            </div>
            <br/>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <InputLabel style={{ margin: '35px 15px 0 0' }} htmlFor="prefix-direction">Prefix Direction</InputLabel>
                <Select
                    style={{ width: '25%' }}
                    id="prefix-direction"
                    name="prefixDirection"
                    value={values.prefixDirection}
                    onChange={handleChange}
                    disabled={modalLoading}
                    fullWidth={false}
                    variant="outlined">
                    { 
                        direction.map((direction, index) => 
                            <MenuItem key={index} value={direction.value}>{direction.value}</MenuItem>
                        )
                    }
                </Select>
            </div>
            <br/>
            <TextField
                    id="street"
                    label="*Street Name"
                    margin="normal"
                    name="street"
                    type="street"
                    onChange={handleChange}
                    value={values.street}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={touched.street && Boolean(errors.street)}
                    variant="outlined"/>
            {(props.touched.street && props.errors.street) && <span className="error-prompt"  style={{ paddingRight: '31.5%' }}>{props.touched.street ? props.errors.street : ""}</span>}
            {!(props.touched.street && props.errors.street) && <span style={{ color: '#456a7f', fontStyle: 'italic', paddingRight: '41%' }}>Example: Main</span> }   
            
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <TextField
                    style={{ width: '50%', paddingRight: '10%' }}
                    id="streetType"
                    label="Street Type"
                    margin="normal"
                    name="streetType"
                    type="streetType"
                    onChange={handleChange}
                    value={values.streetType}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={false}
                    error={touched.streetType && Boolean(errors.streetType)}
                    variant="outlined"/>
                <div style={{ width: '50%', display: 'flex', alignItems:'baseline' }}>
                    <InputLabel style={{ margin: '0 15px 0 0px' }} htmlFor="post-direction">Post Direction</InputLabel>
                    <Select
                        style={{ width: '60%' }}
                        id="post-direction"
                        name="postDirection"
                        value={values.postDirection}
                        onChange={handleChange}
                        disabled={modalLoading}
                        fullWidth={false}
                        variant="outlined"
                    >
                        { 
                            direction.map((direction, index) => 
                                <MenuItem key={index} value={direction.value}>{direction.value}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            </div>
            {(props.touched.streetType && props.errors.streetType) && <span className="error-prompt"  style={{ paddingRight: '41.5%' }}>{props.touched.streetType ? props.errors.streetType : ""}</span>}
            {!(props.touched.streetType && props.errors.streetType) && <span style={{ color: '#456a7f', fontStyle: 'italic', paddingRight: '35%' }}>Examples: Ave, Pl, St</span> } 

            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <TextField
                    style={{ width: '50%', paddingRight: '10%' }}
                    id="city"
                    label="*City"
                    margin="normal"
                    name="city"
                    type="city"
                    onChange={handleChange}
                    value={values.city}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={false}
                    error={touched.city && Boolean(errors.city)}
                    variant="outlined"/> 
                <div style={{ width: '50%'} }>
                    <div style={{ display: 'flex', alignItems:'baseline', marginBottom: 10 }}>
                        <InputLabel style={{ margin: '0 15px 0 0px' }} htmlFor="state-province" error={touched.stateProvince && Boolean(errors.stateProvince)}>*State/Province</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            id="state-province"
                            name="stateProvince"
                            value={values.stateProvince}
                            onChange={handleChange}
                            disabled={modalLoading}
                            onBlur={handleBlur}
                            fullWidth={false}
                            error={touched.stateProvince && Boolean(errors.stateProvince)}
                            variant="outlined"
                        >
                            {values.country === 'US' ?
                                states.sort(function(a, b){
                                    if(a.value < b.value) { return -1; }
                                    if(a.value > b.value) { return 1; }
                                    return 0;
                                }).map((state, index) => 
                                    <MenuItem key={index} value={state.value}>{state.value}</MenuItem>
                                ) :
                                provinces.sort(function(a, b){
                                    if(a.value < b.value) { return -1; }
                                    if(a.value > b.value) { return 1; }
                                    return 0;
                                }).map((state, index) => 
                                    <MenuItem key={index} value={state.value}>{state.value}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    {touched.stateProvince && errors.stateProvince ? <span className="error-prompt">{touched.stateProvince ? errors.stateProvince : ""}</span> : '' }
                </div>
            </div>
            {(props.touched.city && props.errors.city) && <span className="error-prompt"  style={{ paddingRight: '41.5%' }}>{props.touched.city ? props.errors.city : ""}</span>}
            
            {!(props.touched.city && props.errors.city) && <span style={{ color: '#456a7f', fontStyle: 'italic', paddingRight: '35%' }}>Example: Metropolis</span>}
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <TextField
                    style={{ width: '50%', paddingRight: '10%' }}
                    id="zip"
                    label="*Zip/Postal Code"
                    margin="normal"
                    name="zip"
                    type="zip"
                    onChange={handleChange}
                    value={values.zip}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={touched.zip && Boolean(errors.zip)}
                    variant="outlined"/>
                <div style={{ width: '50%', display: 'flex', alignItems:'baseline' }}>
                    <InputLabel style={{ margin: '0 15px 0 0px' }} htmlFor="country" error={touched.country && Boolean(errors.country)}>*Country</InputLabel>
                    <Select
                        style={{ width: '85%' }}
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={touched.country && Boolean(errors.country)}
                        variant="outlined"
                    >
                        {
                            countries.map((country, index) => 
                            <MenuItem variant="outlined" key={index} value={country.value}>{country.value}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            </div>
            {(props.touched.zip && props.errors.zip) && <span className="error-prompt"  style={{ paddingRight: '41.5%' }}>{props.touched.zip ? props.errors.zip : ""}</span>}
            {!(props.touched.zip && props.errors.zip) && <span style={{ color: '#456a7f', fontStyle: 'italic', paddingRight: '35%' }}>Example: 12345</span> }   
        </div>
    );
}

export default AlternateAddress;